<template>
    <div>
        <div v-if="user_group.indexOf('测试组')==-1">
            <cw403></cw403>
        </div>
        <div v-if="user_group.indexOf('测试组')>-1">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    用户组管理
                </el-breadcrumb-item>
            </el-breadcrumb>
             <el-button style="border-radius:10px;width:100px;float:right;margin-top:-25px;margin-right:30px;" icon="el-icon-document-remove" type="primary"  @click="dialogVisible = true" plain>页面说明</el-button>
        </div>
        <div class="container">
            <div class="handle-box">
                <p style="font-size:14px;color:gray;margin-top:-20px;margin-bottom:10px;">添加/删除组权限</p>
                <el-select v-model="groupName" clearable placeholder="选择用户组" filterable collapse-tags style="margin-right:10px;width:240px;" @change="userSelect">
                    <el-option
                        v-for="item in list1"
                        :key="item.label"
                        :label="item.value"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="groupQX" clearable placeholder="选择权限" style="margin-right:10px;">
                    <el-option
                        v-for="item in list2"
                        :key="item.label"
                        :label="item.value"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div style="display:inline;">
                    <el-button v-if="groupName==''||groupQX==''" disabled style="margin-right:10px;margin-bottom:10px;" icon="el-icon-circle-plus-outline" type="info" >让用户组添加所选择的权限</el-button>
                    <el-button v-if="groupName!=''&&groupQX!=''" style="margin-right:10px;margin-bottom:10px;" icon="el-icon-circle-plus-outline" type="primary" >让用户组添加所选择的权限</el-button>
                </div>
                <div style="display:inline;">
                    <el-button v-if="groupName==''||groupQX==''" disabled style="margin-bottom:10px;" icon="el-icon-circle-plus-outline" type="info" >删除选择的组的权限</el-button>
                    <el-button v-if="groupName!=''&&groupQX!=''" style="margin-bottom:10px;" icon="el-icon-circle-plus-outline" type="danger" >删除选择的组的权限</el-button>
                </div>
            </div>
            <div>
                <p style="font-size:14px;color:gray;margin-top:-20px;margin-bottom:10px;">添加组</p>
                <el-input style="width:200px;margin-right:10px;" placeholder="写入添加的组名（中文）" v-model="input1" clearable></el-input>
                <el-button v-if="input1==''" disabled style="margin-bottom:20px;margin-right:10px;" icon="el-icon-edit" type="info">添加用户组</el-button>
                <el-button v-if="input1!=''" style="margin-bottom:20px;margin-right:10px;" icon="el-icon-edit" type="primary">添加用户组</el-button>
            </div>
            <div>
                <p style="font-size:14px;color:gray;margin-top:-10px;margin-bottom:10px;">新建权限</p>
                <el-input style="width:200px;margin-right:10px;" placeholder="写入权限name(中文)" v-model="input2" clearable></el-input>
                <el-input style="width:200px;margin-right:10px;" placeholder="写入权限codename(英文)" v-model="input3" clearable></el-input>
                <el-button v-if="input2==''||input3==''" disabled style="margin-bottom:20px;margin-right:10px;" icon="el-icon-edit" type="info">新建权限</el-button>
                <el-button v-if="input2!=''&&input3!=''" style="margin-bottom:20px;margin-right:10px;" icon="el-icon-edit" type="primary">新建权限</el-button>
            </div>

            <el-table
                v-loading = "pictLoading"
                element-loading-text = "数据正在加载中"
                :data="search(query).slice((currentPage1-1)*pagesize,currentPage1*pagesize)"
                height="550"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                :header-cell-style="{'color':'#696969'}"
            >
            
                <el-table-column prop="项目" label="group_name" min-width="40"></el-table-column>
                <el-table-column prop="平台" label="all_permissions" min-width="120"></el-table-column>
                 
            </el-table>
            <div class="pagination" style="margin-right:30px;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage1"
                    :page-sizes="[50, 100, 150, 200]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
            
        </div>

        
        </div>
    </div>
</template>

<script>
import { fetchData } from '../../api/index';
import json2csv from 'json2csv'
import cw403 from './403.vue';
export default {
    name: 'basetable',
    components: {
      cw403,
    },
    data() {
        return {
            user_group:localStorage.getItem('user-group'),
            input1:'',
            input2:'',
            input3:'',
            groupName:'',
            groupQX:'',
            groupName:'',
            groupQX:'',
            pictLoading:true,
            pagesize:50,
            currentPage1: 1,
            total:0,
            value1: '',
            value2: '',

            dialogVisible: false,
            type:false,
            list1: [
                {label: '选项1',value: 'HeroSummoner'}, 
                {label: '选项2',value: 'IdleSummoner'},
                {label: '选项3',value: 'IslandFantasy'}, 
                {label: '选项4',value: 'StickmanFighting'}, 
                {label: '选项5',value: '塔防'},
                {label: '选项6',value: '塔防典藏版'},
                {label: '选项7',value: '小鸡'},
                {label: '选项8',value: '撸撸塔典藏'},
                {label: '选项9',value: '放置召唤'},
                {label: '选项10',value: '无限骑士'},
                {label: '选项11',value: '温泉'},
                {label: '选项12',value: '温泉CP'},
                {label: '选项13',value: '火柴人日语'},
                {label: '选项14',value: '火柴人神射手'},
                {label: '选项15',value: '火柴人繁体'},
                {label: '选项16',value: '火柴人韩语'},
                {label: '选项17',value: '神圣核心'},
                ],
            list2:[{label:'1',value:'ios'},{label:'2',value:'android'}],
            QQ:{
            date:[],
            gameName: [],
            phone:[],
            country:[]
            },
            query:'',
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1
        };
    },
    created() { 
        /* this.getData(); */
        this.getTable();
    },
    methods: {
        getTable(){
        //发起get请求
        this.$http.get('basicdata/test/',{
                        headers:{
                         "Authorization":"JWT " + localStorage.getItem("jwt-token")
                            }
                    })
        .then(function(res){
          if(res.data.code==200)
          {
              this.tableData=res.data.list
              this.total=res.data.list.length
              this.pictLoading=false
          }
          }).catch(function(err){
                console.log(err);
            })

            /* this.$axios({
                method:'get',
                url:'basicdata/test/'
            }).then((response) =>{          //这里使用了ES6的语法
                console.log(response)       //请求成功返回的数据
            }).catch((error) =>
                console.log(error)       //请求失败返回的数据
            ) */
            
      },
        
        
        // 获取 easy-mock 的模拟数据
        getData() {
            fetchData(this.query).then(res => {
                /* console.log(res); */
                this.tableData = res.list;
                this.pageTotal = res.pageTotal || 50;
            });
        },
        // 触发搜索按钮
        handleSearch() {
            /* this.$set(this.query, 'pageIndex', 1);
            this.getData(); */
            /* alert(this.value2+this.QQ.gameName+this.QQ.phone+this.QQ.country) */
                /* var newList=[]
                this.tableData.forEach(item => {
                    for(var i=0;i<this.QQ.gameName.length;i++){
                    if(item.gameName.indexOf(this.QQ.gameName[i])!=-1)
                    newList.push(item)
                    }
                });
                this.tableData=newList */
                /* this.total=this.tableData.length */
        },
        
        // 分页导航
        /* handlePageChange(val) {
            this.$set(this.query, 'pageIndex', val);
            this.getData();
        }, */
        handleSizeChange(val) {
            this.pagesize=val;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        //搜索
        search(query){
        return this.tableData.filter(item=>{
                        //注意：ES6中，为字符串提供了一个新方法，叫做String.prototype.includes('要包含的字符串')
                        //如果包含，则返回true，否则返回false
                        if(item.项目.includes(query))
                        return item
                    })
      },
      //导出csv文件
       download(){
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
            navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    getSTime (val) {
        this.value2 = val
    },
    handleSizeChange(val) {
            this.pagesize=val;
        },
        handleCurrentChange(val) {
            this.currentPage1 = val;
        },
    userSelect(){

    },
        

    },
};
</script>
<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>

